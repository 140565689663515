/* Constants */
.text-center{
    text-align: center;
}
.text-white-color{
    color:var(--white) !important;
}
.text-primary-color{
    color:var(--primary-color) !important;
}
.grey{
    color: #707070 !important;
}
.bg-primary-color{
    background-color:var(--primary-color) !important;
}
body{
    background: black;
}
::-webkit-scrollbar {
    width: 0px  /* hidden scrollbar */
}
html {
    scroll-behavior: smooth;
    transition: 0.8s;
}
a:link{
    color:#707070;
}
a:visited {
    color:#707070;
}
a:hover{
    color:var(--primary-color);
}
li{
    list-style-type: none;    
}


/* -----------     user-header part         -----------------*/
.user-header{
    margin: 0px 100px;
    background: transparent;
    display:block;
    position: sticky;
    margin-right: 100px;
    backdrop-filter: blur(5px);
    height: 140px;
    width: calc(100% - 200px);
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items:center;
    overflow: hidden;
    top: 0;
    z-index: 5;
}
.logo-img{
    height: 80px ; 
    width: 200px;
}
.user-navbar ul{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 240px;
}
.user-navbar-items{
    font-family: var(--poppins-font);
    list-style: none;
    color:white;
    cursor: pointer;
    font-size: 16px;
}
ul{
    margin: 0;
    padding: 0;
}
/* ----------   verify-container  -------------  */
.verify-container{
    font-family: var(--poppins-font);
    color:white;
    margin: 0px 100px;
    padding-bottom: 50px;
}
.user-header-text{
    font-family: var(--poppins-font);
    color:var(--primary-color);
    font-size: 32px;
    margin: unset;
    font-weight: 600;
}
.sub-text{
    font-family: var(--poppins-font);
    color:#707070 !important;
    font-size: 24px;
    margin: unset;
}
.sub-textp{
    font-family: var(--poppins-font);
    color:var(--primary-color) !important;
    font-size: 24px;
    margin: unset;
}
.verify-button{
    background: var(--primary-color) !important;
    color: white !important;
    border: 1px solid var(--primary-color) !important;
    height: 50px;
    cursor: pointer;
    font-size: 16px;
    width: 225px !important;
    border-radius: 25px !important;
}
.verify-button:hover{
    color: var(--primary-color)!important;
    background-color:transparent !important;
    border: 1px solid var(--primary-color);
}
.verify-button-container{
    color:#707070;
    border: 1px solid #cacaca;
    width: 800px;
    padding: 24px;
    text-align: start;
    border-radius: 10px;
   
}
.divider{
    margin:10px 0px;
    color:white;
    background-color: var(--greyModalColor);
}
.icon-text-container{
    display: flex;
    align-items: center;
    color: #707070;
}
.verify-icon{
    font-size: 32px;
    margin-right: 18px;
    color: #707070 !important;
}
.link{
    color:#707070;
    font-family: var(--poppins-font);
    font-size: 18px;
}
/* ------------- let's get verified -------------- */
.user-or-text {
    border: none;
    border-top: 1px double var(--greyModalColor);
    color: var(--greyModalColor);
    overflow: visible;
    text-align: center;
    width: 1000px;
}
.user-or-text:after {
    background:var(--white);
    content: "OR";
    padding: 0 20px;
    position: relative;
    top: -13px;
    color: #707070;
}
.copy-button{
    color:var(--primary-color) !important;
    margin:unset;
}
.ant-checkbox{
    margin-right: 8px;
}
.ant-checkbox-inner{
    background-color: transparent !important;
    height: 22px;
    width:  22px;
    border-radius: 5px;
}
/* ----- application-data section ------------  */
.app-data-container{
    width: 800px;
 border: 1px solid #cacaca;
    border-radius: 15px;
    margin: auto;
    padding: 30px;
}
.ant-form-vertical .ant-form-item-label > label{
    color: white;
}
.app-input {
    background-color: var(--white) !important;
    border-radius: 5px;
    height: 35px;
    width: 450px;
    
    border-color: var(--greyModalColor);
    color: #707070!important;
}
.ant-form-item-explain-error{
    text-align: start;
}
.ant-picker-input > input{
    color:#707070!important;
}
.ant-picker-suffix{
    color:var(--white) !important;
}
.back-button{
    color:var(--primary-color) !important;
    border:1px solid var(--primary-color) !important;
    background-color: transparent !important;
    border-radius: 20px;
    width: 150px;
    height: 40px;
}
.back-button:hover {
    background-color: var(--primary-color) !important;
    color: var(--white) !important;

}
.next-button{
    color:var(--white) !important;
    border:1px solid var(--primary-color) !important;
    background-color: var(--primary-color) !important;
    border-radius: 20px;
    width: 150px;
    height: 40px;
}
.next-button:hover {
    background-color: transparent !important;
    color: var(--primary-color) !important;
}
.step-container{
    width: 800px;
    border-radius: 15px;
    margin: auto;
    padding: 30px;
}
/* font and color changes of title */
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
    width: 200px;
    color:var(--white);
    font-size: 20px;
    text-align: left;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
    width: 200px;
    color:var(--white);
    font-size: 20px;
    text-align: left;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
    width: 200px;
    font-size: 20px;
    color:var(--white);
    text-align: left;
}
/* Dot style of steps */
.ant-steps-label-vertical .ant-steps-item-icon{
    background-color: var(--primary-color);
    color:var(--white);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon{
    box-shadow: 0 30px 80px rgba(66, 133, 244, 0.5);
    border-color: var(--primary-color);
    color:var(--white)
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{
    background-color: transparent;
    border:unset;
    color:var(--white)
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon{
    background-color: var(--primary-color);
    border:1px solid var(--white);
    box-shadow: 0px 0px 20px 0px var(--primary-color);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after{
    height: 3px;
}

/* identity document */
.ant-select-selector{
    color:#707070 !important;
    background-color: transparent !important;
    border-color: var(--greyModalColor) !important;
}
.ant-select-arrow{
    color:var(--white) !important
}
.ant-select-clear{
    color:var(--white) !important;
    background-color: transparent;
}
.ant-form-vertical .ant-form-item-label > label {
    color: #353535;
}
.app-select-input{
    border-radius: 5px !important;
    background-color:var(--white) !important;
    height: 35px;
    width: 700px !important;
    color: #8c8c8c !important;
}
.app-radio-input{
    color:#707070
}
.ant-radio-inner{
    background-color: transparent;
}
.document-rule-list > li{
    color:#707070;
    list-style-type: disc;
    list-style-position: inside;
}
/* upload document container */
.ant-upload,.ant-upload-list-picture-card .ant-upload-list-item{
    color:var(--white) !important;
    background-color: transparent !important;
    width: 100% !important;
    min-height: 180px !important;
    border-radius: 10px !important;
}
.ant-upload-list-picture .ant-upload-list-item-error, .ant-upload-list-picture-card .ant-upload-list-item-error{
    border-color: var(--primary-color);
}
.ant-upload-list-picture-card-container{
    width: 100% !important;
    height: 180px !important;
    border-radius: 10px !important;
}
.upload-icon> *{
    color: #707070 !important;
    height: 40px !important;
    width: 40px !important;
}
.process-img{
    height: 150px;
}
.ant-form-item-control-input{
    align-items: flex-start;
}

/* mobile screen */
@media only screen and (max-width: 480px) {
    /* header */
    .user-header{
        margin: 0px 15px;
        height: 80px;
        width: calc(100% - 30px);
        justify-content:space-between;
    }
    .logo-img{
        height: 40px;
        width: 100px;
    }
    .user-navbar ul{
        width: 150px;
    }
    .user-navbar-items{
        font-size: 12px;
    }
    /* verify user */
    .verify-container{
        margin: 0px 15px;
    }
    .user-header-text{
        font-size: 20px;
    }
    .sub-text{
        font-size: 12px;

    }
    .sub-textp{
        font-size: 12px;
    }
    .verify-button{
        height: 40px;
        font-size: 14px;
        width: 100% !important;
    }
    .verify-button-container{
        width: 100%;
        padding: 18px;
    }
    .divider{
        margin:8px 0px;
    }
    .verify-icon{
        font-size: 20px;
        margin-right: 14px;
    }
    .link{
        font-size: 14px;
    }
    /* ------------- let's get verified -------------- */
    .user-or-text {
        width: 100%;
    }
    .ant-checkbox-inner{
        height: 18px;
        width:  18px;
    }
    .qrcode-img{
        height: 150px;
    }
    /* ----- application-data section ------------  */
    .app-data-container{
        width:100%;
        padding: 10px;
        box-shadow: unset;
    }
    .app-input {
        max-width: 100%;
    }
    .back-button,.next-button{
        height: 35px;
        width: 110px;
    }
    .step-container{
        width: 100%;
        padding: 10px;
    }
    /* font and color changes of title */
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
        width: 130px;
        font-size: 14px;
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
        width: 130px;
        font-size: 14px;
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
        width: 130px;
        font-size: 14px;
    }
    /* Dot style of steps */
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after{
        height: 3px;
    }
    .app-select-input{
        max-width: 100% !important;
    }
    .upload-icon> *{
        height: 30px !important;
        width: 30px !important;
    }
}
@media only screen and (min-width: 481px) and (max-width: 767px) {
    .user-header{
        margin: 0px 25px;
        height: 80px;
        width: calc(100% - 50px);
        justify-content:space-between;
    }
    .logo-img{
        height: 40px;
        width: 100px;
    }
    .user-navbar ul{
        width: 200px;
    }
    .user-navbar-items{
        font-size: 14px;
    }
/* verify user */
    .verify-container{
        margin: 0px 25px;
    }
    .user-header-text{
        font-size: 22px;
    }
    .sub-text{
        font-size: 14px;
    }
    .sub-textp{
        font-size: 14px;
    }
    .verify-button{
        height: 40px;
        font-size: 14px;
        width: 200px !important;
    }
    .verify-button-container{
        width: 480px;
        padding: 18px;
    }
    .divider{
        margin:8px 0px;
    }
    .verify-icon{
        font-size: 22px;
        margin-right: 14px;
    }
    .link{
        font-size: 14px;
    }
     /* ------------- let's get verified -------------- */
     .user-or-text {
        width: 90%;
    }
    .ant-checkbox-inner{
        height: 20px;
        width:  20px;
    }
    .qrcode-img{
        height: 150px;
    }
    /* ----- application-data section ------------  */
    .app-data-container{
        width: 420px;
        padding: 20px;
    }
    .app-input {
        width: 360px;
    }
    .step-container{
        width: 420px;
        padding: 20px;
    }
    /* font and color changes of title */
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
        width: 150px;
        font-size: 14px;
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
        width: 150px;
        font-size: 14px;
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
        width: 150px;
        font-size: 14px;
    }
    /* Dot style of steps */
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon{
        padding: 0px 6px;
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
        height: 16px;
        width: 14px;
    }
    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
        height: 16px;
        width: 14px;
    }
    .ant-steps-dot .ant-steps-item-tail::after, .ant-steps-dot.ant-steps-small .ant-steps-item-tail::after{
        margin-left: 14px;
        margin: 3px;
    }
    .app-select-input{
        width: 380px !important;
    }
    .upload-icon> *{
        height: 30px !important;
        width: 30px !important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .user-header{
        margin: 0px 25px;
        height: 100px;
        width: calc(100% - 50px);
        justify-content:space-between;
    }
    .logo-img{
        height: 50px;
        width: 120px;
    }
    .user-navbar ul{
        width: 200px;
    }
    .user-navbar-items{
        font-size: 14px;
    }
/* verify user */
    .verify-container{
        margin: 0px 25px;
    }
    .user-header-text{
        font-size: 24px;
    }
    .sub-text{
        font-size: 18px;
    }
    .sub-textp{
        font-size: 18px;
    }
    .verify-button{
        height: 40px;
        font-size: 14px;
        width: 200px !important;
    }
    .verify-button-container{
        width: 600px;
        padding: 18px;
    }
    .divider{
        margin:10px 0px;
    }
    .verify-icon{
        font-size: 22px;
        margin-right: 16px;
    }
    .link{
        font-size: 16px;
    }
    /* ------------- let's get verified -------------- */
    .user-or-text {
        width: 700px;
    }
    .ant-checkbox-inner{
        height: 22px;
        width:  22px;
    }
    .qrcode-img{
        height: 200px;
    }
    /* ----- application-data section ------------  */
    .app-data-container{
        width: 500px;
        padding: 25px;
    }
    .app-input {
        width: 400px;
    }
    .step-container{
        width: 500px;
        padding: 25px;
    }
    /* font and color changes of title */
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
        width: 200px;
        font-size: 16px;
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
        width: 200px;
        font-size: 16px;
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
        width: 200px;
        font-size: 16px;
    }
    /* Dot style of steps */
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon{
        padding: 0px 6px;
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
        height: 16px;
        width: 16px;
    }
    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
        height: 16px;
        width: 16px;
    }
    .ant-steps-dot .ant-steps-item-tail::after, .ant-steps-dot.ant-steps-small .ant-steps-item-tail::after{
        margin-left: 14px;
        margin: 3px;
    }
    .app-select-input{
        width: 420px !important;
    }
    .upload-icon> *{
        height: 30px !important;
        width: 30px !important;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1219px) {
    .user-header{
        margin: 0px 25px;
        height: 100px;
        width: calc(100% - 50px);
        justify-content:space-between;
    }
    .logo-img{
        height: 50px;
        width: 120px;
    }
    .user-navbar ul{
        width: 200px;
    }
    .user-navbar-items{
        font-size: 14px;
    }
/* verify user */
    .verify-container{
        margin: 0px 25px;
    }
    .user-header-text{
        font-size: 24px;
    }
    .sub-text{
        font-size: 18px;
    }
    .sub-textp{
        font-size: 18px;
    }
    .verify-button{
        height: 50px;
        font-size: 18px;
        width: 250px !important;
    }
    .verify-button-container{
        width: 600px;
        padding: 18px;
    }
    .divider{
        margin:10px 0px;
    }
    .verify-icon{
        font-size: 24px;
        margin-right: 18px;
    }
    .link{
        font-size: 18px;
    }
    /* ------------- let's get verified -------------- */
    .user-or-text {
        width: 800px;
    }
    .ant-checkbox-inner{
        height: 22px;
        width:  22px;
    }
    .qrcode-img{
        height: 200px;
    }
    /* ----- application-data section ------------  */
    .app-data-container{
        width: 600px;
        padding: 30px;
    }
    .app-input {
        width: 450px;
    }
    .step-container{
        width: 600px;
        padding: 30px;
    }
    /* font and color changes of title */
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
        width: 200px;
        font-size: 18px;
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
        width: 200px;
        font-size: 18px;
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
        width: 200px;
        font-size: 18px;
    }
    /* Dot style of steps */
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon{
        padding: 1px 7px;
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
        height: 18px;
        width: 18px;
    }
    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
        height: 18px;
        width: 18px;
    }
    .ant-steps-dot .ant-steps-item-tail::after, .ant-steps-dot.ant-steps-small .ant-steps-item-tail::after{
        margin-left: 14px;
        margin: 4px;
    }
    .app-select-input{
        width: 550px !important;
    }
    .upload-icon> *{
        height: 35px !important;
        width: 35px !important;
    }
}
@media only screen and (min-width: 1220px) and (max-width: 1439px) {
    .user-header{
        margin: 0px 50px;
        height: 120px;
        width: calc(100% - 100px);
    }
    .logo-img{
        height: 70px;
        width: 160px;
    }
    .user-navbar ul{
        width: 200px;
    }
    .user-navbar-items{
        font-size: 17px;
    }
/* verify user */
    .verify-container{
        margin: 0px 50px;
    }
    .user-header-text{
        font-size: 28px;
    }
    .sub-text{
        font-size: 22px;
    }
    .sub-textp{
        font-size: 22px;
    }
    .verify-button{
        height: 50px;
        font-size: 20px;
        width: 250px !important;
    }
    .verify-button-container{
        width: 700px;
        padding: 20px;
    }
    .divider{
        margin:10px 0px;
    }
    .verify-icon{
        font-size: 26px;
        margin-right: 20px;
    }
    .link{
        font-size: 20px;
    }
    /* ------------- let's get verified -------------- */
    .user-or-text {
        width: 900px;
    }
    .ant-checkbox-inner{
        height: 22px;
        width:  22px;
    }
    /* ----- application-data section ------------  */
    .app-data-container{
        width: 700px;
        padding: 30px;
    }
    .app-input {
        width: 500px;
    }
    .step-container{
        width: 700px;
        padding: 30px;
    }
       /* font and color changes of title */
       .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
        width: 200px;
        font-size: 18px;
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
        width: 200px;
        font-size: 18px;
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
        width: 200px;
        font-size: 18px;
    }
    /* Dot style of steps */
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon{
        padding: 1px 7px;
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
        height: 18px;
        width: 18px;
    }
    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
        height: 18px;
        width: 18px;
    }
    .ant-steps-dot .ant-steps-item-tail::after, .ant-steps-dot.ant-steps-small .ant-steps-item-tail::after{
        margin-left: 14px;
        margin: 4px;
    }
    .app-select-input{
        width: 600px !important;
    }
    .upload-icon> *{
        height: 35px !important;
        width: 35px !important;
    }
}
@media only screen and (min-width: 1940px){
    .user-header{
        margin: 0px 100px;
        height: 200px;
        width: calc(100% - 200px);
    }
    .logo-img{
        height: 180px;
        width: 250px;
    }
    .user-navbar ul{
        width: 500px;
    }
    .user-navbar-items{
        font-size: 24px;
    }
/* verify user */
    .verify-container{
        margin: 0px 100px;
    }
    .user-header-text{
        font-size: 42px;
    }
    .sub-text{
        font-size: 32px;
    }
    .sub-textp{
        font-size: 32px;
    }
    .verify-button{
        height: 80px;
        font-size: 28px;
        width: 400px !important;
    }
    .verify-button-container{
        width: 1000px;
        padding: 32px;
    }
    .divider{
        margin:10px 0px;
    }
    .verify-icon{
        font-size: 40px;
        margin-right: 24px;
    }
    .link{
        font-size: 32px;
    }
    /* ------------- let's get verified -------------- */
    .user-or-text {
        width: 1300px;
    }
    .ant-checkbox-inner{
        height: 30px;
        width:  30px;
    }
    /* ----- application-data section ------------  */
    .app-data-container{
        width: 700px;
        padding: 40px;
    }
    .app-input {
        width: 500px;
        height: 50px;
        font-size: 25px;
    }
    .step-container{
        width: 700px;
        padding: 40px;
    }
    /* font and color changes of title */
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
        width: 250px;
        font-size: 24px;
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
        width: 250px;
        font-size: 24px;
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
        width: 250px;
        font-size: 24px;
    }
    /* Dot style of steps */
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon{
        padding: 3px 10px;
        
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
        height: 24px;
        width: 24px;
    }
    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
        height: 24px;
        width: 24px;
    }
    .ant-steps-dot .ant-steps-item-tail::after, .ant-steps-dot.ant-steps-small .ant-steps-item-tail::after{
        margin-left: 14px;
        margin: 4px;
        color:var(--white)
    }
    .back-button{
        font-size: large;
        width: 200px;
        height: 50px;
    }
    .ant-picker-input > input:placeholder-shown{
        font-size: 24px;
    }
    .next-button{
        font-size: large;
        width: 200px;
        height: 50px;
    }
    .app-select-input{
        width: 600px !important;
    }
    .upload-icon> *{
        height: 50px !important;
        width: 50px !important;
    }
}