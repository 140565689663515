:root {
  --primary-color: #4285f4;
  --white: #ffffff;
  --greyModalColor: #b4b4b4;
}
@font-face {
  font-family: var(--poppins-font);
  src: url(../../assets/fonts/Poppins-Regular.ttf);
}
.primary-color {
  color: var(--primary-color);
  cursor: pointer;
}
.ant-modal {
  /* border:1px solid red; */
  border-radius: 15px;
  /* box-shadow: 0px 0px 20px 0px var(--primary-color); */
  overflow: hidden;
}

.ant-modal-header {
  border: none;
  background-color: var(--white);
  padding: 24px 16px 0px;
}
.ant-modal-title {
  font-family: var(--poppins-font);
  font-size: 25px;
  font-weight: 700;
  color:var(--primary-color);
  text-align: center;
}
.ant-modal-body {
  background-color: var(--white);
  color: var(--white);
}
.ant-modal-close-x {
  color: rgba(112, 112, 112, 1);
  font-size: 24px;
  line-height: 70px;
}
.ant-input-affix-wrapper > input.ant-input {
  background-color: var(--white);
  color: rgba(112, 112, 112, 1);
}
.ant-space-vertical {
  width: 100%;
}
.email-input {
  background-color: var(--white) !important;
  border-radius: 5px;
  height: 35px;
  border-color: rgba(112, 112, 112, 1);
  color:  rgba(112, 112, 112, 1);
}
.submit-button {
  color: var(--white) !important;
  height: 35px;
  width: 100%;
  background-color: #4285f4 !important;
  border-radius: 20px;
  border: 1px solid var(--primary-color);
}
.submit-button:hover {
  color: var(--primary-color) !important;
  background-color:transparent!important;
  border: var(--primary-color) solid 1px;
}
.ant-checkbox-input {
  background-color: var(--primary-color) !important;

}
.checkbox-btn {
  color: rgba(112, 112, 112, 1);
  
}
.ant-checkbox.ant-checkbox-checked{
  background:var(--primary-color) !important;
  border-radius: 5px;
}
.or-text {
  border: none;
  border-top: 1px double var(--greyModalColor);
  color: var(--greyModalColor);
  overflow: visible;
  text-align: center;
}
.or-text:after {
  background:var(--white);
  content: "OR";
  padding: 0 20px;
  position: relative;
  top: -13px;
  color: var(--greyModalColor);
}
.continue-text {
  color:  rgba(112, 112, 112, 1);
  text-align: center;
  margin: 0;
}
.icon {
  height: 24px;
  cursor: pointer;
}
.email-desc {
  font-family: var(--poppins-font);
  font-size: 20px;
  text-align: center;
  color: var(--greyModalColor);
  margin: 0;
}
.icon-desc {
  color: var(--primary-color);
  padding-top: 10px;
  cursor: pointer;
}
.ant-form-item-with-help .ant-form-item-explain {
  min-height: unset !important;
}
.ant-form-item {
  margin-bottom: unset;
}

/* Mobile screen  <= 480 */
@media only screen and (max-width: 480px) {
  .ant-modal-title {
    font-size: 18px;
  }
  .ant-modal-close-x {
    font-size: 18px;
  }
  .email-desc {
    font-size: 15px;
  }
}
