@import 'antd/dist/antd.css';

*{      /* disallow the copy content */
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    user-select: none;
}
@font-face {
    font-family: Trap;
    src: url(../src/assets/fonts/Trap-Medium.otf);
}
@font-face {
    font-family: Poppins;
    src: url(../src/assets/fonts/Poppins-Regular.ttf);
}
:root {
    --primary-color:#4285F4;
    --white: #FFFFFF;
    --grey: #CACACA;
    --greyModalColor: #b4b4b4;
    /* Font style */
    --poppins-font:Poppins;
    /* dis-allow zoom website in mobile screen */
    touch-action: pan-x pan-y;
    height: 100%;
}