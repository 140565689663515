.max-width-200 {
    max-width: 200px;
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
  }
.max-width-250 {
    max-width: 250px;
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
  }