/* mobile screen : max-width: 576px    <=576*/
/* laptop screen : (min-width: 768px) and (max-width: 992px)   */
/* desktop screen : (min-width: 1440px) and (max-width: 1940px) */
/* Big screen : (min-width: 1940px) */

.text-center {
    text-align: center;
  }
  body {
    background:var(--white)!important;
  }
  ::-webkit-scrollbar {
    width: 0px; /* this means scroll is hidden of main website */
  }
  html {
    scroll-behavior: smooth;
    transition: 0.8s;
  }
  a:link {
    color: var(--white);
  }
  a:visited {
    color: var(--white);
  }
  a:hover {
    color: var(--primary-color);
  }
  li {
    list-style-type: none;
  }
  /* Start scroll indicator */
  #progressBarContainer {
    position: fixed;
    z-index: 10;
    width: 100%;
    top: 0;
    left: 0;
  }
  #progressBar {
    background: var(--primary-color);
    transform-origin: top left;
    transform: scale(0, 0);
    opacity: 0.8;
  }
  #progressBarContainer,
  #progressBar {
    height: 8px;
  }
  /* End scroll indicator */
  
  .header {
    padding: 0px 100px;
    background: transparent;
    display: block;
    position: fixed;
    margin-right: 100px;
    backdrop-filter: blur(5px);
    height: 140px;
    width: calc(100% );
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    top: 0;
    z-index: 5;
  }
  .logo-img {
    height: 80px;
    width: 200px;
  }
  .navbar ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 600px;
  }
  .navbar-items li a {
    font-family: var(--poppins-font) !important;
    list-style: none;
    color: #707070;
    cursor: pointer;
    font-size: 16px;
  }
  .navbar-items > li a:hover {
    color: var(--primary-color);
  }
  ul {
    margin: 0;
    padding: 0;
  }
  .menu {
    display: none;
    background: transparent !important;
    color: #707070 !important;
    border: none;
    padding: 0 !important;
    cursor: pointer;
  }
  .signin-button {
    background: transparent;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    height: 40px;
    cursor: pointer;
    width: 110px !important;
    border-radius: 20px !important;
  }
  .signin-button:hover {
    background: var(--primary-color);
    color: var(--white);
  }
  .signup-button {
    background: var(--primary-color);
    border: 1px solid  var(--primary-color);
    color: var(--white);
    height: 40px;
    cursor: pointer;
    width: 110px !important;
    border-radius: 20px !important;
  }
  .signup-button:hover {
    background: transparent;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
  }
  .buynow-button {
    background: var(--primary-color);
    color: var(--white);
    border: 1px solid var(--primary-color);
    height: 40px;
    cursor: pointer;
    width: 110px !important;
    border-radius: 20px !important;
  }
  .buynow-button:hover,
  .buycoin-button:hover {
    color: var(--primary-color) !important;
    background-color: transparent !important;
    border: 1px solid var(--primary-color);
  }
  
  .abs-height {
    height: 140px;
  }
  /*  ---------------    Home    -------------------- */
  .home-back {
    background: url("../../assets/websiteIcon/New_BG.svg");
    background-position: 100% 100%;
    background-repeat: no-repeat;
  }


  .haveto-img {
    float: right;
    width: 900px;
  }
  .home {
    display: flex;
    height: 620px;
    justify-content: space-between;
    align-items: center;
    margin: 0px 100px;
    line-height: 1.35;
  }
  .home-order {
    order: 1 !important;
  }
  .text {
    font-style: normal;
    font-weight: 500;
    font-size: 68px;
    color: var(--primary-color);
    font-family: Trap;
  }
  
  .buycoin-button {
    background: var(--primary-color) !important;
    color: var(--white)!important;
    border: 1px solid var(--primary-color) !important;
    margin-top: 15px;
    font-size: 20px;
    height: 60px;
    width: 250px;
    border-radius: 30px !important;
  }
  .buycoin-button:hover {
    background: transparent !important;
  }
  .robot-img {
    width: 500px;
  }
  .explore-more {
    margin: 0px 100px;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  .explore-button,
  .explore-button > a {
    background: transparent;
    border: transparent;
    color: var(--primary-color) !important;
    font-size: 16px;
    float: left;
  }
  
  /*  ---------------------- Start Navbar style ----------------- */
  .overlay {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    background-color: rgba(210, 210, 210, 0.1);
    overflow-y: hidden;
    transition: 0.7s;
    backdrop-filter: blur(36px);
  }
  
  .overlay-content {
    position: relative;
    top: 15%;
    width: 100%;
    text-align: center;
  }
  
  .overlay a {
    padding: 5px;
    text-decoration: none;
    font-size: 20px;
    color: #707070 !important;
    display: block;
    transition: 0.3s;
  }
  
  .overlay a:hover,
  .overlay a:focus {
    color: var(--primary-color);
  }
  
  .overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
  }
  
  @media screen and (max-height: 450px) {
    .overlay {
      overflow-y: auto;
    }
    .overlay a {
      font-size: 20px;
    }
    .overlay .closebtn {
      font-size: 40px;
      top: 15px;
      right: 35px;
    }
  }
  
  /* ------------------   End navbar style  ------------------------ */
  
  /* ---------------   Features ------------------  */
  .header-text {
    font-family: Trap;
    font-size: 60px;
    color: var(--primary-color);
  }
  .features {
    font-family: var(--poppins-font);
    color: var(--primary-color);
    margin: 50px 100px;
  }
  .feature-space {
    padding-top: 15px;
  }
  .feature-title {
    font-family: var(--poppins-font);
    font-size: 24px;
  }
  .feature-order1 {
    order: 1;
  }
  .feature-order2 {
    order: 2;
  }
  .feature-desc {
    font-family: var(--poppins-font);
    color: #707070;
    font-size: 16px;
    text-align: justify; /* justify text */
  }
  .feature-img {
    width: 90%;
    height: 90%;
  }
  .gif-img {
    background: url("../../assets/websiteIcon/features/feature.gif");
    background-color: var(--white);
    background-blend-mode: hard-light;
  
    height: 1100px;
    width: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .video-icon {
    height: 150px;
  }
  .video {
    position: absolute;
    border: none;
    box-shadow: 5px 5px black;
    width: 1200px;
    height: 600px;
  }
  .road-map {
    font-family: var(--poppins-font);
    color:var(--primary-color)  ;
    margin: 50px 100px;
  }
  /*  -------  steps of road-map style ------- */
  .ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
    background-color: var(--primary-color) !important;
    color: var(--white);
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    background-color: transparent;
    color: var(--white);
    border: 1px solid var(--white);
    border-radius: 50%;
    padding: 5px 7px;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: rgba(112, 112, 112, 1) !important;
    font-weight: 700;
    font-size: medium;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: var(--primary-color) !important;
    font-weight: 700;
    font-size: medium;
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color:rgba(178, 178, 178, 1) !important;
    font-weight: 700;
    font-size: medium;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: rgba(112, 112, 112, 1) !important;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: var(--primary-color) !important;
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color:rgba(178, 178, 178, 1)!important;
  }
  /*  ------- end steps of road-map style ------- */
/* --------- Direct Purchase Page ------------*/
.direct-purchase-page {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.purchase-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  width: 30%;
  height: auto; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.purchase-card:hover {
  border: 1px solid var(--primary-color);
  box-shadow: 0px 4px 50px 0px rgba(66, 133, 244, 0.3);
}

.purchase-card h2 {
  color: var(--primary-color);
  font-size: 24px;
  margin-bottom: 10px;
}
.extra-info {
  background-color: rgba(193, 216, 255, 1);
  color:var(--primary-color);
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 50px;
  margin: 0 auto 10px;
  width: max-content;
}
.purchase-card p {
  color: #707070;
  font-size: 16px;
  margin-bottom: 20px;
}

.purchase-card button {
  background: var(--primary-color);
  color: var(--white);
  border: 1px solid  var(--primary-color);
  border-radius: 20px !important;
  cursor: pointer;
  padding: 10px 20px;
}

.purchase-card button:hover {
  background: transparent;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
}
@media (max-width: 670px) {
  .direct-purchase-page {
    flex-direction: column;
    align-items: center;
  }

  .purchase-card {
    width: 100%;
    margin-bottom: 20px;
  }

  .purchase-card:last-child {
    margin-bottom: 0;
  }
}
/* ------------  buy full node --------------  */

.sub-header-text{
  font-size: 2em;
  margin-bottom: 20px;
  color: #707070;
  text-align: center;
}
.sub-header-text span{
  color: var(--primary-color);  
}

.node-cards {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.node-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 50px;
  width: 48.5%;
  height: auto; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.node-card:hover {
  border: 1px solid var(--primary-color);
  box-shadow: 0px 4px 50px 0px rgba(66, 133, 244, 0.3);
}

.node-card h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: var(--primary-color);
  text-align: center;
}

.node-card h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: var(--primary-color);
  text-align: center;
}

.node-card p {
  margin-bottom: 10px;
  color: #707070;
  text-align: center;
}

.node-card ul {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.node-card ul li {
  margin-bottom: 5px;
  color: #707070;
  list-style-type: none; /* Remove the default bullet */
  position: relative;
}
.node-card ul li::before {
  content: "\2192"; /* Unicode for right arrow */
  color: var(--primary-color); /* Same color as the text */
  position: absolute; /* Position it relative to the list item */
  left: -20px; /* Adjust the positioning as needed */
}

.node-card button {
  background: var(--primary-color);
  color: var(--white);
  border: 1px solid  var(--primary-color);
  border-radius: 20px !important;
  cursor: pointer;
  padding: 10px 20px;
}

.node-card button:hover {
  background: transparent;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}
@media (max-width: 670px) {
  .node-cards {
    flex-direction: column;
    align-items: center;
  }

  .node-card {
    width: 100%;
    margin-bottom: 20px;
  }

  .node-card:last-child {
    margin-bottom: 0;
  }
  .sub-header-text{
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #707070;
    text-align: center;
  }
}
  /* ------------  coint table --------------  */
  .coin-list {
    color: var(--white);
    text-align: left;
    padding-left: 25px;
  }
  .coin-logo {
    height: 20px;
    width: 20px;
    border-radius: 20%;
  }
  .coin-text {
    color: var(--primary-color) !important;
    padding-top: 12px;
  }
  /*  ---------------    FAQ    -------------------- */
  .faq {
    font-family: var(--poppins-font);
    color: var(--white);
    margin: 50px 100px;
  }
  .faq-img {
    width: 600px !important;
  }
  .faq-collapse {
    border: none;
  }
  .ant-collapse-content {
    border: none;
  }
  .faq-qa > .ant-collapse > .ant-collapse-item {
    border: none;
  }
  .faq-qa > .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: var(--white);
    border: none;
    padding-left: unset;
  }
  .ant-collapse {
    background-color: transparent;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    background-color: black !important;
    padding: 0px 0px 0px 35px;
    margin: 0;
  }
  .faq-header {
    color: var(--white);
    font-family: var(--poppins-font);
    font-size: 22px;
    background-color: black;
  }
  .faq-visible {
    color: #707070;
    text-align: justify;
    background-color: black;
    margin: unset;
  }
  .caret-icon {
    color: var(--primary-color) !important;
    font-size: 20px !important;
    margin-top: 8px;
  }
  .about-text {
    color: #707070;
    font-size: 24px;
    font-family: var(--poppins-font);
    text-align: justify;
  }
  .about-order1 {
    order: 1;
  }
  .about-order2 {
    order: 2;
  }
  /* ---------------    Footer ----------------  */
  .footer {
    background: rgb(255, 255, 255);
    box-shadow: 0 30px 80px rgba(66, 133, 244, 0.5);
    border-radius: 50%/10% 10% 0 0;
    border-top: 1px ;
    height: max-content;
  }
  .footer-text {
    color: #707070;
    font-size: 22px;
    font-family: var(--poppins-font);
    margin-bottom: 10px;
  }
  .footer-input {
    color: black !important;
    height: 40px;
    border-radius: 20px !important;
    background-color: var(--white) !important;
  }
  .footer-button {
    background-color: var(--primary-color) !important;
    color: var(--white) !important;
    height: 35px;
    width: max-content;
    border-radius: 20px;
    border: 1px solid var(--primary-color);
  }
  .footer-button:hover {
    background-color: transparent !important;
    color: var(--primary-color) !important;
  }
  .footer-menu {
    text-align: start;
  }
  .footer-menu li {
    margin: 5px;
  }
  .footer-menu li a{
    color: #707070;
  }
  .footer-menu li a:hover{
    color: var(--primary-color);
  }
  .white-paper {
    height: 150px;
  }
  .copyright-text {
    color: grey;
    margin-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }
  /*  ----scroll to top button ------  */
  .scroll-top {
    position: fixed !important;
    border-radius: 50% !important;
    bottom: 30px;
    cursor: pointer;
    right: 30px;
    padding: 10px 20px;
    background-color: var(--primary-color);
    border: none;
    text-align: center;
    z-index: 2;
  }

  /* Media query for mobile phone */
  @media only screen and (max-width: 480px) {
    /* .navbar ul{
          display: none;
      } */
    .navbar ul {
      position: fixed;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 550px;
      height: 250px;
    }
    .navbar-items {
      font-size: 12px;
    }
    .menu {
      display: block;
    }
    .header {
      padding: 0px 15px;
      height: 80px;
      width: calc(100% );
      justify-content: space-between;
    
    }
    .abs-height {
      height: 80px;
    }
    .logo-img {
      height: 40px;
      width: 100px;
    }
    .text {
      font-size: 26px;
    }
    .plus-icon {
      height: 26px;
      width: auto;
    }
    .home {
      display: flex;
      flex-direction: column;
      height: 575px;
      justify-content: space-between;
      align-items: center;
      margin: 0px 15px;
    }
    .home ul {
      order: 2;
    }
    .home-back {
      background: url("../../assets/websiteIcon/BG_for_Mob.svg");
      background-repeat: no-repeat;
      background-size: 100%;
    }
    .buycoin-button {
      font-size: 15px;
      height: 38px;
      width: 100%;
      text-align: center;
      border-radius: 20px !important;
    }
    .feature-order2 {
      order: 1;
    }
    .robot-img {
      width: 100%;
    }
    .explore-more {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0px 15px;
    }
    .explore-text {
      display: none;
    }
    .haveto-img {
      width: 100%;
    }
    .header-text {
      font-size: 30px;
    }
    .features {
      margin: 25px 15px 25px 15px;
    }
    .feature-title {
      font-size: 18px;
    }
    .feature-desc {
      color: #707070;
      font-size: 15px;
    }
    .feature-img {
      width: 250px;
      height: 180px;
    }
    .gif-img {
      height: 300px;
    }
    .video-icon {
      height: 80px;
    }
    .video {
      width: 300px;
      height: 150px;
    }
    .faq {
      margin: 0px 15px;
    }
    .faq-img {
      display: none;
    }
    .faq-header {
      font-size: 18px;
    }
    .about-text {
      font-size: 18px;
    }
    .about-order1 {
      order: 2;
    }
    .about-order2 {
      order: 1;
    }
    .footer {
      border-radius: 80%/10% 10% 0 0;
      height: max-content;
      margin-top: 50px;
      width: 100%;
    }
    .ant-row-space-between {
      justify-content: center;
    }
    .footer-text {
      font-size: 14px;
      margin-bottom: 10px;
    }
    .email-text {
      text-align: start;
    }
    .footer-menu {
      display: none;
    }
    .footer-icon {
      margin-top: 15px;
    }
    .footer-button {
      width: 100%;
    }
    .white-paper {
      height: 100px;
    }
  }
  @media only screen and (min-width: 481px) and (max-width: 576px) {
    /*Done because order not proper matched in single row in feature & sm size is 576*/
    .feature-order1 {
      order: 2 !important;
    }
  }
  /* Media query for tablet screen */
  @media only screen and (min-width: 481px) and (max-width: 767px) {
    .navbar ul {
      display: none;
    }
    .navbar-items {
      font-size: 12px;
    }
    .menu {
      display: block;
    }
    .header {
      padding: 0px 25px;
      height: 80px;
      width: calc(100% );
      justify-content: space-between;
    }
    .buycoin-button {
      font-size: 15px;
      height: 40px;
      width: 170px;
      border-radius: 20px !important;
    }
    .abs-height {
      height: 80px;
    }
    .logo-img {
      height: 50px;
      width: 120px;
    }
    .text {
      font-size: 22px;
    }
    .plus-icon {
      height: 28px;
      width: auto;
    }
    .home {
      height: 300px;
      justify-content: space-between;
      align-items: center;
      margin: 0px 25px;
    }
  
    .robot-img {
      width: 250px;
    }
    .explore-more {
      margin: 0px 25px;
    }
    .explore-text {
      font-size: 15px;
      display: inline;
    }
    
    .haveto-img {
      width: 300px;
    }
    
    
    .header-text {
      font-size: 30px;
    }
    .features {
      margin: 0px 25px;
    }
    .feature-title {
      font-size: 20px;
    }
    .feature-desc {
      color: #707070;
      font-size: 15px;
    }
    .feature-img {
      width: 220px;
      height: 180px;
    }
    .feature-order1 {
      order: 1;
    }
    .gif-img {
      height: 400px;
    }
    .video-icon {
      height: 80px;
    }
    .video {
      width: 450px;
      height: 250px;
    }
    .faq {
      margin: 0px 25px;
    }
    .faq-img {
      display: none;
    }
    .faq-header {
      font-size: 18px;
    }
    .about-text {
      font-size: 18px;
    }
    .about-order1 {
      order: 2;
    }
    .about-order2 {
      order: 1;
    }
    .footer {
      border-radius: 80%/10% 10% 0 0;
      height: max-content;
      margin-top: 50px;
    }
    .footer-text {
      font-size: 18px;
      margin-bottom: 10px;
    }
    .white-paper {
      height: 120px;
    }
  }
  /* Media query for mini laptop screen */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header {
      padding: 0px 25px;
      height: 100px;
      width: calc(100% );
      background-color: transparent;
      justify-content: space-between;
    }
    .abs-height {
      height: 100px;
    }
    .logo-img {
      height: 40px;
      width: 100px;
    }
  
    .navbar ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 550px;
    }
    .buycoin-button {
      font-size: 15px;
      height: 40px;
      width: 180px;
      border-radius: 20px !important;
    }
    .navbar-items {
      font-size: 12px;
    }
    .signin-button {
      height: 30px;
      width: 80px !important;
      border-radius: 20px !important;
    }
    .signup-button {
      height: 30px;
      width: 80px !important;
      border-radius: 20px !important;
    }
    .buynow-button {
      height: 30px;
      width: 80px !important;
    }
    .text {
      font-size: 24px;
    }
    .plus-icon {
      height: 24px;
      width: auto;
    }
    .home {
      display: flex;
      height: 420px;
      justify-content: space-between;
      align-items: center;
      margin: 0px 25px;
    }
    .robot-img {
      width: 350px;
    }
    .explore-more {
      margin: 0px 25px;
    }
    .haveto-img {
      float: right;
      width: 500px;
    }
    .header-text {
      font-size: 40px;
    }
    .features {
      margin: 0px 25px;
    }
    .feature-title {
      font-size: 18px;
    }
    .feature-desc {
      color: #707070;
      font-size: 16px;
    }
    .feature-img {
      width: 250px;
      height: 200px;
    }
    .explore-text {
      font-size: 18px;
      display: inline;
    }
    .gif-img {
      height: 550px;
    }
    .video-icon {
      height: 100px;
    }
    .video {
      width: 450px;
      height: 300px;
    }
    .faq {
      margin: 0px 25px;
    }
    .faq-img {
      display: none;
    }
    .faq-header {
      font-size: 20px;
    }
    .about-text {
      font-size: 20px;
    }
    .about-order1 {
      order: 2;
    }
    .about-order2 {
      order: 1;
    }
    .footer {
      border-radius: 60%/10% 10% 0 0;
      height: max-content;
      margin-top: 50px;
      border: 1px ;
    }
    .footer-text {
      font-size: 20px;
      margin-bottom: 10px;
    }
    .white-paper {
      height: 140px;
    }
  }
  /* Media query for laptop screen */
  @media only screen and (min-width: 992px) and (max-width: 1219px) {
    .header {
      padding: 0px 50px;
      height: 100px;
      width: calc(100% );
    }
    .abs-height {
      height: 100px;
    }
    .logo-img {
      height: 50px;
      width: 150px;
    }
    .navbar ul {
      font-size: 15px;
      width: 725px;
    }
    .buycoin-button {
      font-size: 15px;
      height: 35px;
      width: 180px;
    }
    .navbar-items {
      font-size: 13px;
    }
    .signin-button {
      height: 35px;
      width: 100px !important;
      border-radius: 20px !important;
    }
    .signup-button {
      height: 35px;
      width: 100px !important;
      border-radius: 20px !important;
    }
    .buynow-button {
      height: 35px;
      width: 100px !important;
    }
    .text {
      font-size: 36px;
    }
    .plus-icon {
      height: 36px;
      width: auto;
    }
    .home {
      display: flex;
      height: 420px;
      justify-content: space-between;
      align-items: center;
      margin: 0px 50px;
    }
    .robot-img {
      width: 375px;
    }
    .explore-more {
      margin: 0px 50px;
    }
    .haveto-img {
      float: right;
      width: 500px;
    }
    .header-text {
      font-size: 40px;
    }
    .features {
      margin: 0px 50px;
    }
    .feature-title {
      font-size: 18px;
    }
    .feature-desc {
      color: #707070;
      font-size: 16px;
    }
    .feature-img {
      width: 300px;
      height: 200px;
    }
    .explore-text {
      font-size: 15px;
      display: inline;
    }
    .gif-img {
      height: 650px;
    }
    .video-icon {
      height: 150px;
    }
    .video {
      width: 700px;
      height: 450px;
    }
    .faq {
      margin: 0px 50px;
    }
    .faq-img {
      width: 500px !important;
    }
    .faq-header {
      font-size: 20px;
    }
    .about-text {
      font-size: 20px;
    }
    .footer {
      box-shadow: 0 30px 80px rgba(66, 133, 244, 0.5);
      border-radius: 50%/10% 10% 0 0;
      border-top: 1px ;
    }
    .footer-text {
      font-size: 20px;
      margin-bottom: 10px;
    }
    .footer-menu {
      text-align: start;
    }
    .footer-menu li {
      margin: 4px;
    }
    .white-paper {
      height: 150px;
    }
  }
  /* Media query for desktop screen */
  @media only screen and (min-width: 1220px) and (max-width: 1439px) {
    .header {
      padding: 0px 50px;
      height: 120px;
      width: calc(100% );
    }
    .abs-height {
      height: 120px;
    }
    .logo-img {
      height: 70px;
      width: 160px;
    }
    .navbar ul {
      font-size: 17px;
      width: 800px;
    }
    .buycoin-button {
      font-size: 17px;
      height: 45px;
      width: 200px;
    }
    .navbar-items {
      font-size: 13px;
    }
    .signin-button {
      height: 35px;
      width: 100px !important;
      border-radius: 20px !important;
    }
    .signup-button {
      height: 35px;
      width: 100px !important;
      border-radius: 20px !important;
    }
    .buynow-button {
      height: 35px;
      width: 100px !important;
    }
    .text {
      font-size: 58px;
    }
    .plus-icon {
      height: 58px;
      width: auto;
    }
    .home {
      display: flex;
      height: 420px;
      justify-content: space-between;
      align-items: center;
      margin: 0px 50px;
    }
    .robot-img {
      width: 450px;
    }
    .explore-more {
      margin: 0px 50px;
    }
    .haveto-img {
      float: right;
      width: 600px;
    }
    .header-text {
      font-size: 40px;
    }
    .features {
      margin: 0px 50px;
    }
    .feature-title {
      font-size: 18px;
    }
    .feature-desc {
      color: #707070;
      font-size: 16px;
    }
    .feature-img {
      width: 350px;
      height: 250px;
    }
    .explore-text {
      font-size: 15px;
      display: inline;
    }
    .gif-img {
      height: 800px;
    }
    .video-icon {
      height: 150px;
    }
    .video {
      width: 700px;
      height: 450px;
    }
    .faq {
      margin: 0px 50px;
    }
    .faq-img {
      width: 500px !important;
    }
    .faq-header {
      font-size: 20px;
    }
    .about-text {
      font-size: 20px;
    }
    .footer {
      box-shadow: 0 30px 80px rgba(66, 133, 244, 0.5);
      border-radius: 50%/10% 10% 0 0;
      border-top: 1px ;
    }
    .footer-text {
      font-size: 20px;
      margin-bottom: 10px;
    }
    .footer-menu {
      text-align: start;
    }
    .footer-menu li {
      margin: 4px;
    }
    .white-paper {
      height: 150px;
    }
  }
  
  /* Media query for large desktop screen */
  @media only screen and (min-width: 1940px) {
    .header {
     padding: 0px 100px;
      height: 200px;
      width: calc(100% );
      background-color: transparent;
      justify-content: space-between;
    }
    .abs-height {
      height: 200px;
    }
    .logo-img {
      height: 180px;
      width: 250px;
    }
    .navbar ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 1200px;
    }
    .navbar-items {
      font-size: 24px;
    }
    .signin-button {
      height: 60px;
      width: 150px !important;
      border-radius: 50px !important;
    }
    .signup-button {
      height: 60px;
      width: 150px !important;
      border-radius: 50px !important;
    }
    .buynow-button {
      height: 60px;
      width: 150px !important;
      border-radius: 50px !important;
    }
    .buycoin-button {
      height: 60px;
      font-size: 30px;
      width: 350px !important;
      border-radius: 50px !important;
    }
    .home {
      height: 720px;
      margin: 0px 150px;
    }
    .text {
      font-size: 90px;
    }
    .robot-img {
      width: 650px;
    }
    .explore-more {
      margin: 0px 150px;
    }
    .haveto-img {
      float: right;
      width: 1200px;
    }
    .header-text {
      font-size: 74px;
    }
    .features {
      margin: 50px 150px;
    }
    .feature-title {
      font-size: 35px;
    }
    .feature-desc {
      color: #707070;
      font-size: 25px;
    }
    .feature-img {
      width: 450px;
      height: 350px;
    }
    .explore-text {
      font-size: 32px;
      display: inline;
    }
    .gif-img {
      height: 1200px;
    }
    .video-icon {
      height: 200px;
    }
    .video {
      width: 1450px;
      height: 850px;
    }
    .faq {
      margin: 0px 150px;
    }
    .faq-header {
      font-size: 28px;
    }
    .about-text {
      font-size: 28px;
    }
    .footer {
      box-shadow: 0 30px 80px rgba(66, 133, 244, 0.5);
      border-radius: 50%/10% 10% 0 0;
      border-top: 1px ;
    }
    .footer-text {
      font-size: 32px;
      margin-bottom: 10px;
    }
    .footer-input {
      height: 50px;
      width: 400px;
      font-size: 20px;
      border-radius: 50px !important;
    }
    .footer-button {
      height: 50px;
      font-size: 20px;
      border-radius: 50px !important;
    }
    .footer-menu {
      text-align: start;
      font-size: 24px;
    }
    .footer-menu li {
      margin: 5px;
    }
    .white-paper {
      height: 200px;
    }
  }